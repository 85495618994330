var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recorder"},[(_vm.memory == 1)?_c('h6',{staticClass:"recorder-h"},[_vm._v("点击词汇 开始跟读")]):_c('h6',{staticClass:"recorder-h"},[_vm._v("点击句子 开始跟读")]),(_vm.lrcList.length > 0 && this.loading)?_c('div',{staticClass:"recorder-list"},_vm._l((_vm.lrcList),function(item,index){return _c('div',{key:index,staticClass:"recorder-list-item",on:{"click":function($event){return _vm.clickItem(index)}}},[(item.isOpen)?[_c('div',{staticClass:"recorder-list-item-read"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"number"},[_vm._v(_vm._s(index + 1 + "/" + _vm.lrcList.length))]),(item.isReading)?[_vm._m(0,true)]:(item.isAudioing)?[_vm._m(1,true)]:[_vm._m(2,true)]],2),_c('div',{staticClass:"content"},[(item.evaWord)?_c('p',{staticClass:"sentence",domProps:{"innerHTML":_vm._s(item.evaWord)}}):_c('p',{staticClass:"sentence",domProps:{"innerHTML":_vm._s(item.word)}})]),_c('div',{staticClass:"fun"},[(item.isReading)?[_c('div',{staticClass:"fun-box fun-media",on:{"click":_vm.clickReadPause}},[_c('i-pause-one',{attrs:{"theme":"outline"}}),_c('p',[_vm._v("播放中")])],1)]:[(item.isRecodering || item.isAudioing)?_c('div',{staticClass:"fun-box fun-media"},[_c('i-play',{attrs:{"theme":"outline"}}),_c('p',[_vm._v("播放原音")])],1):_c('div',{staticClass:"fun-box fun-media",on:{"click":_vm.clickReadPlay}},[_c('i-play',{attrs:{"theme":"outline"}}),_c('p',[_vm._v("播放原音")])],1)],(item.isRecodering)?[_c('div',{staticClass:"fun-box fun-recording",on:{"click":_vm.stopRecordAudio}},[_c('i-voice',{attrs:{"theme":"outline"}}),_c('p',[_vm._v("点击停止")])],1)]:[_c('div',{staticClass:"fun-box fun-recording",on:{"click":function($event){return _vm.startRecordAudio(item.isReading, item.isAudioing)}}},[_c('i-voice',{attrs:{"theme":"outline"}}),_c('p',[_vm._v("点击录音")])],1)],(item.userScore && item.userScore > 0)?[(
                  item.audioRecordUrl == '' &&
                  (item.isRecodering || item.isReading)
                )?_c('div',{staticClass:"fun-box fun-score"},[_c('span',{staticClass:"i-score"},[_vm._v(_vm._s(item.userScore))]),_c('p',[_vm._v("播放录音")])]):(item.isAudioing)?_c('div',{staticClass:"fun-box fun-score",on:{"click":_vm.clickItemPauseRecorder}},[_c('span',{staticClass:"i-score"},[_vm._v(_vm._s(item.userScore))]),_c('p',[_vm._v("播放中")])]):_c('div',{staticClass:"fun-box fun-score",on:{"click":_vm.clickItemPlayRecorder}},[_c('span',{staticClass:"i-score"},[_vm._v(_vm._s(item.userScore))]),_c('p',[_vm._v("播放录音")])])]:[_vm._m(3,true)]],2),_c('div',{ref:'mxy' + index,refInFor:true,staticClass:"read-box",attrs:{"id":'mxy' + index}})])]:[_c('div',{staticClass:"recorder-list-item-look"},[(item.userScore)?_c('div',{staticClass:"score"},[_vm._v(_vm._s(item.userScore))]):[(_vm.zoom == '小儿郎')?_c('img',{staticClass:"photo",attrs:{"src":"https://res.cdn.quyixian.com/front/小儿郎.png","alt":""}}):(_vm.zoom == '青蛙')?_c('img',{staticClass:"photo",attrs:{"src":"https://res.cdn.quyixian.com/front/gua.png","alt":""}}):(_vm.zoom == '长颈鹿')?_c('img',{staticClass:"photo",attrs:{"src":"https://res.cdn.quyixian.com/front/chang.png","alt":""}}):_c('img',{staticClass:"photo",attrs:{"src":"https://res.cdn.quyixian.com/front/dog.png","alt":""}})],_c('p',{staticClass:"sentence",domProps:{"innerHTML":_vm._s(item.evaWord != '' ? item.evaWord : item.word)}})],2)]],2)}),0):_c('van-loading',{attrs:{"size":"24px","vertical":""}},[_vm._v("加载中...")]),_c('audio',{ref:"audio",staticStyle:{"height":"0"},attrs:{"id":"audio","src":_vm.filePath}}),_c('audio',{ref:"partAudio",staticStyle:{"height":"0"},attrs:{"id":"partAudio","src":_vm.partFilePath}}),_vm._m(4)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box box-media"},[_c('ul',[_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box box-audio"},[_c('ul',[_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('ul',[_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"}),_c('p',{staticClass:"box-p"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fun-box fun-score"},[_c('span',{staticClass:"i-score i-score1"}),_c('p')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recorder-fixed",attrs:{"id":"fixed"}},[_c('canvas',{attrs:{"id":"canvas"}})])
}]

export { render, staticRenderFns }